//参考：https://www.jianshu.com/p/c66adc327553
//参考：https://www.hangge.com/blog/cache/detail_2554.html
import {Loading} from 'element-ui'
import _ from 'lodash'
import {getLang} from "@/utils/lang";
//防抖

let needLoadingRequestCount = 0
let loading

function startLoading() {
    loading = Loading.service({
        lock: true,
        text: getLang() ==='en'?'Loading...':'处理中...',
        background: 'rgba(0, 0, 0, 0.5)'
    })
}

function endLoading() {
    loading.close()
    loading = null;
}

const tryCloseLoading = () => {
    if (needLoadingRequestCount === 0 && loading) {
        endLoading()
    }
}

export function showFullScreenLoading() {
    if (needLoadingRequestCount === 0 && !loading) {
        startLoading()
    }
    needLoadingRequestCount++
}

export function tryHideFullScreenLoading() {

    if (needLoadingRequestCount <= 0) return
    needLoadingRequestCount--

    if (needLoadingRequestCount === 0) {
        tryCloseLoading()
        //_.debounce(tryCloseLoading, 300)() //防抖操作，可用可不用，去掉防抖可视觉上提升接口加载速度（没有300ms关闭等待）
    }
}
