import axios from 'axios' //基于axios封装
import {Notification} from 'element-ui'; //基于element通知的错误提示
import {showFullScreenLoading, tryHideFullScreenLoading,} from './RequestHelper' //基于element封装请求Loading
import {getToken, clearToken} from "@/utils/token";
import {getLang} from "@/utils/lang";

const $ = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    timeout: 15000
})

// 请求拦截器，封装loading和jwt token
$.interceptors.request.use((config) => {
    const {showLoading} = config;
    if (showLoading) {
        showFullScreenLoading()
    }
    config.headers['App-Language'] = getLang();
    config.headers['Accept-Language'] = getLang();
    const token = getToken()
    if (token) {
        config.headers['Authorization'] = 'Bearer ' + token; // 使用jwt Bearer规范
    }
    return config
}, (error) => {
    return Promise.reject(error)
})

// 定义异常错误提示
const errorHandler = (error) => {
    if (error.config.showLoading) {
        tryHideFullScreenLoading()
    }
    const err = error.response && error.response.data ? error.response.data : {}
    if (error.config.catchError) {
        showErrorMessage(err)
    }
    if (error.response.status === 401) {
        clearToken()
        window.location.href = "/user/login"
        return Promise.reject(error)
    }
    return Promise.reject(error)
}

function showErrorMessage(res) {
    Notification.error({
        title: '请求错误',
        message: res.message || '发生未知服务器错误'
    });
}

// 响应拦截器，处理数据返回、异常拦截和鉴权拦截
$.interceptors.response.use((response) => {
    const {showLoading} = response.config;
    if (showLoading) {
        tryHideFullScreenLoading()
    }
    const res = response.data
    switch (res.code) {
        case 200:
            return res.data;
        case 401://接口要求登录,清空当前token，并刷新页面（正常导航守卫会自动切换到login）
            clearToken()
            window.location.href = "/user/login"
            return Promise.reject(res)
        default://http码正常，业务code异常提示
            const {catchError} = response.config;
            if (catchError) {
                showErrorMessage(res)
            }
            return Promise.reject(res)
    }

}, errorHandler)

const defaultConfig = {showLoading: true, catchError: true} //默认使用loading，默认拦截提示异常
export default {
    get: (url, config) => $.get(url, {...defaultConfig, ...config}),
    put: (url, data, config) => $.put(url, data, {...defaultConfig, ...config}),
    post: (url, data, config) => $.post(url, data, {...defaultConfig, ...config}),
    patch: (url, data, config) => $.patch(url, data, {...defaultConfig, ...config}),
    delete: (url, data, config) => $.delete(url, {...defaultConfig, ...config})
}
